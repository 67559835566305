export default defineI18nConfig(() => ({
  legacy: false,
  fallbackLocale: 'cs',
  pluralRules: {
    cs(choice) {
      choice = Math.abs(choice)
      if (choice < 2) {
        return choice
      }

      if (choice <= 4) {
        return 2
      }

      return 3
    },
  },
}))
